import { getQueryString, handleError, page_size } from "../apiUtils";
import { createAxiosInstance } from "../apiUtils";

export async function getOrganizationsList(token: string, query: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/organizations/?pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getOrganizationDetails(token: string, org_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/organizations/" + org_id);
        return resp.data;
    } catch (err) {
        handleError(err);
    }  
}

export async function getOrganizationMembers(token: string, org_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/organizations/" + org_id + "/members/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }   
}

export async function inviteOrganizationMember(token: string, org_id: string, member: Object){
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.put("/v2/organizations/" + org_id + "/members/invite/", member);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function removeOrganizationMember(token: string, org_id: string, member_id: string, member: Object){
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/organizations/" + org_id + "/members/remove/", {"member_ids": [member_id]} );
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function addOrganization(token: string, organization: Object) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/organizations/", organization);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function updateOrganization(token: string, org_id: string, organization: Object) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.patch("/v2/organizations/"+ org_id + "/", organization);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}
