import { getQueryString, handleError, page_size } from "../apiUtils";
import { createAxiosInstance } from "../apiUtils";

export async function getConnectionsList(token: string, query: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/connections/?pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    } 
}

export async function getConnectionProviderTypes(token: string, query?: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/integrations/types/?action_type=pull&pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getConnectionDetails(token: string, connection_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/connections/" + connection_id + "/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getConnectionApiKey(token: string, connection_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/integrations/" + connection_id + "/api-key/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getIntegrationDetails(token: string, connection_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/integrations/" + connection_id + "/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getConnectionLog(token: string, query: object){
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/logs/?pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function createNewConnection(token: string, new_connection: object) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/integrations/", new_connection);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function addConnectionDestinations(token: string, route_id: string, destination_array: any) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.patch("/v2/routes/" + route_id + "/", destination_array);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function updateConnection(token: string, integration_id: string, newConfigs: any) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.patch("/v2/integrations/" + integration_id + "/", newConfigs);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function testConnectionAction(token: string, integration_id: string, action_id: string, data: any) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/integrations/" + integration_id + "/actions/" + action_id + "/execute/", data);
        return { data: resp.data, status: resp.status };
    } catch (err) {
        handleError(err);
    }
}