
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Connection, Organization, Provider } from "../common/ObjectTypes";
import SelectInput from "../common/SelectInput";
import { AttentionIcon, HideString, ShowString } from "../common/ProjectIcons";

type ConnectionOverviewProps = {
    connection: Connection,
    errors: any,
    apiKey: string,
    connectionProvider: Provider,
    onChangeConnectionName: Function,
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    onChangeConnectionOwner: Function
}

const ConnectionDetailsOverview = ({
    connection,
    errors,
    apiKey,
    connectionProvider,
    onChangeConnectionName,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    onChangeConnectionOwner
}: ConnectionOverviewProps
) => {
    const { t } = useTranslation();
    const [keyVisible, setKeyVisible] = useState(false);
    const toggleVisibility = () => setKeyVisible(!keyVisible)
    return (
        <div className="mt-8">
            {/* div with configuration details */}
            <div className="flex flex-row w-4/5">
                <div className="flex flex-col w-full mr-3">
                    <label
                        className="inline-block hover:cursor-pointer text-secondary-gray text-sm"
                    >
                        Connection Name *
                    </label>
                    <input
                        id="name"
                        placeholder=""
                        value={connectionProvider.name}
                        className="p-1.5 h-auto border border-neutral-gray-200 rounded"
                        onChange={(e) => onChangeConnectionName(e)}
                        disabled={false}
                    >
                    </input>
                    {errors.provider_name && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.provider_name}</p></div>}
                    <div className="flex flex-row items-end w-full">
                        <div className="flex flex-col w-full">
                            <label
                                className="inline-block hover:cursor-pointer text-secondary-gray text-sm mt-5"
                            >
                                API Key
                            </label>
                            <input
                                id="apikey"
                                placeholder=""
                                value={apiKey}
                                className="p-1.5 h-auto border border-neutral-gray-200 rounded"
                                type={keyVisible ? "string" : "password"}
                                // onChange={(e) => onChangeConnectionName(e)}
                                disabled={true}
                            >
                            </input>
                        </div>
                        <button onClick={toggleVisibility} className="mb-2 ml-2 cursor-pointer">
                            {keyVisible ? <HideString /> : <ShowString />}
                        </button>

                    </div>
                </div>
                <div className="flex flex-col w-full ml-3">
                    <label
                        className="inline-block hover:cursor-pointer text-secondary-gray text-sm"
                    >
                        Organization *
                    </label>
                    <SelectInput
                        options={organizations.map((org: Organization) => {
                            return {
                                label: org.name,
                                value: org.id
                            }
                        })}
                        className={"basic-single text-black"}
                        defaultValue={{
                            label: connection.owner.name,
                            value: connection.owner.id
                        }}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        name={"Organization"}
                        onChange={(e: any) => onChangeConnectionOwner(e)}
                        onMenuScrollToBottom={loadMoreOrgs}
                        handleInputChange={orgInputChange}
                    />
                    {errors.provider_owner && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.provider_owner}</p></div>}
                </div>
            </div>
        </div>
    )
};

export default ConnectionDetailsOverview;