import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import EN from "./translations/EN.json"
import ES from "./translations/ES.json"

const resources = {
    en: {
        translation: EN
    },
    es: {
        translation: ES
    }
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        // fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18next;
