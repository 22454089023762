import React, { useState, useEffect, ChangeEvent, ChangeEventHandler } from "react";
import { useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import OrganizationModal from "./OrganizationModal";
import { toast } from 'react-toastify';
import { Organization, buildOrganization } from "../common/ObjectTypes";
import { addOrganization, updateOrganization } from "../../api/organizations/organizationsApi";

type ManageOrganizationModalProps = {
    onToggleModal: Function
    existingOrg?: Organization,
    newOrg: boolean
}

export function ManageOrganizationModal({
    onToggleModal,
    existingOrg,
    newOrg
}: ManageOrganizationModalProps) {
    const {keycloak} = useKeycloak();
    const {org_id} = useParams();
    const toggleModalMouseEvent: React.MouseEventHandler<HTMLButtonElement> = () => onToggleModal(); // function passed from parent needs to be a mouse event
    const [organizaton, setOrganization] = useState<Organization>(existingOrg ? existingOrg : () => buildOrganization())

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const item = event.target
        const item_id: string = item.id
        setOrganization((prevOrg: Organization) => ({
            ...prevOrg,
            [item_id]: item.value,
        }))
    }

    async function handleAddOrganization(){
        if (keycloak.token) {
            await addOrganization(keycloak.token, organizaton);
            toast.success('Organization added', {
                position: toast.POSITION.TOP_RIGHT
            });
            onToggleModal();
        }
    }

    async function handleUpdateOrganization() {
        if (keycloak.token && org_id) {
            await updateOrganization(keycloak.token, org_id, organizaton);
            toast.success('Organization updated', {
                position: toast.POSITION.TOP_RIGHT
            });
            onToggleModal();
        }
    }

    return (
        <OrganizationModal 
            organization={organizaton}
            onToggleModal={toggleModalMouseEvent}
            onChange={handleChange}
            onSave={newOrg ? handleAddOrganization : handleUpdateOrganization}
            newOrg={newOrg}
        />
    )
}