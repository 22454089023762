import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import logo_grey from "../../images/logo_grey.png";
import { useKeycloak } from "@react-keycloak/web";
import { NavLink } from "react-router-dom";
import { ProfileDropdown } from "./ProjectIcons";
import type { KeycloakTokenParsed } from 'keycloak-js';
import { EulaModal } from "./EulaModal";
import { getEula } from "../../api/users/usersApi";

type ParsedToken = KeycloakTokenParsed & {
  email?: string
  preferred_username?: string
  given_name?: string
  family_name?: string
}

const Nav = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const divRef = useRef<HTMLDivElement | null>(null);
  const isLoggedIn = localStorage.getItem("userAuthorized") !== null
  const [subMenu, setSubMenu] = useState(false);
  const [showEULAModal, setShowEULAModal] = useState(false);
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
  const toggleSubMenu = () => setSubMenu(!subMenu)
  const loginUser = () => {
    keycloak.login();
  }

  const getEulaStatus = async () => {
    if (keycloak.token) {
      const results = await getEula(keycloak.token);
      if (!results.accepted) {
        setShowEULAModal(true);
      }
    }
  }

  const logoutUser = () => {
    keycloak.logout()
    localStorage.removeItem("userAuthorized")
  }


  useEffect(() => {
    keycloak.onAuthSuccess = () => {
      localStorage.setItem("userAuthorized", "true");
      keycloak.loadUserProfile();
      getEulaStatus();
    };
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside of the div, so close it.
        setSubMenu(false);
      }
    }

    // Attach the event listener when the component mounts.
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts.
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="top-0 w-full flex flex-wrap">
        <section className="x-auto">
          <nav className=" bg-off-black text-gray-lines w-screen shadow shadow-medium-gray">
            <div className=" flex justify-between px-5 xl:px-12 py-6 flex w-full items-center">
              <div className="flex flex-row items-end">
                <img src={logo_grey} alt="gundi logo" className="h-8 mr-6" />
                <h3 className="text-2xl font-bold text-white font-heading -mb-1 mr-6 pb-0">
                  {process.env.REACT_APP_TEST_VAR}
                </h3>

                <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                  <li>
                    <NavLink data-testid="nav_connections_btn" to="/">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Connections")}</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_destinations_btn" to="/destinations">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Destinations")}</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_sources_btn" to="/sources">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>Sources</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_organizations_btn" to="/organizations">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Organizations")}</span>)}
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="flex flex-row text-neutral-gray-900">
                {/* <MultiLanguage />   */}
                {/* profile dropdown */}
                {isLoggedIn ? (
                  <div className="flex justify-end">
                    <div onClick={toggleSubMenu}>{keycloak?.authenticated ? (parsedToken?.given_name + " " + parsedToken?.family_name) : ""} <ProfileDropdown /></div>

                    <div ref={divRef} className={!subMenu ? "hidden" : " z-30 absolute bg-light-gray mt-7 shadow rounded"}>
                      <ul className="">
                        <li
                          className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                        >
                          <a href="https://projectgundi.org/User-Agreement" target="_blank" rel="noopener noreferrer">Gundi EULA</a>
                        </li>
                        <li
                          data-testid="nav_logout_btn"
                          className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                          onClick={logoutUser}
                        >
                          {t("Log Out")}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <button
                    data-testid="nav_login_btn"
                    type="button"
                    className="cursor-pointer text-gray-lines font-semibold rounded"
                    onClick={loginUser}
                  >
                    {t("Log In")}
                  </button>
                )}
              </div>
            </div>
          </nav>
        </section>
      </div>
      {showEULAModal &&
        <EulaModal />
      }
    </div>
  );
};

export default Nav;