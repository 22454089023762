import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useKeycloakToken } from '../common/useKeycloak';
import ConnectionDetails from "./ConnectionDetails";
import { addConnectionDestinations, getConnectionApiKey, getConnectionDetails, getConnectionLog, getIntegrationDetails, testConnectionAction, updateConnection } from "../../api/connections/connectionsApi";
import { Connection, ConnectionConfiguration, ConnectionLog, Destination, DestinationType, Organization, Provider, Source, buildConnection, buildDestination, buildProvider } from "../common/ObjectTypes";
import Spinner from "../common/Spinner";
import { getDestinationDetails, getDestinationsList } from "../../api/destinations/destinationsApi";
import { getSourcesForConnection } from "../../api/sources/sourcesApi";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { ManageDestinationDetails } from "../destinations/ManageDestinationDetails";
import { ManageDestinationModal } from "../destinations/ManageDestinationModal";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import loadOrganizations from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";
import { filterLogDate, filterLogStatus, filterLogType, logGlobalSearch, removeLogFilterTag, resetLogFilters } from "../activityLogs/ActivityLogsHelperFunctions";
import { changeDestinationGlobalSearch, filterDestinationOwner, filterDestinationType, filterDestinationUrl, removeDestinationFilterTag, resetDestinationFilters } from "../destinations/DestinationHelperFunctions";
import { changeSourceGlobalSearch, filterSourceUrl, removeSourceFilterTag, resetSourceFilters } from "../sources/SourceHelperFunctions";
import loadDestinationUrls from '../../api/destinations/loadDestinationUrls';
import loadDestinationTypes from '../../api/destinations/loadDestinationTypes';
import ConnectionDetailsOverview from "./ConnectionDetailsOverview";
import ConnectionDetailsProvider from "./ConnectionDetailsProvider";
import ConnectionDetailsDestination from "./ConnectionDetailsDestination";
import ConnectionDetailsSource from "./ConnectionDetailsSources";
import ConnectionDetailsLog from "./ConnectionDetailsLog";
import CancelChangesConfirmationModal from "./CancelChangesConfirmationModal";

export function ManageConnectionDetails() {
    type FilterTypes = {
        base_url__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    type LogFilterTypes = {
        log_level: FilterTags[],
        log_type: FilterTags[],
        from_date: FilterTags[],
        to_date: FilterTags[],
        search: string,
        ordering: string
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const { connection_id } = useParams();
    const { subpage } = useParams();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [connection, setConnection] = useState<Connection>(buildConnection());
    const [apiKey, setApiKey] = useState("");
    const [connectionProvider, setConnectionProvider] = useState<Provider>(buildProvider());
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>({
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
    });
    const [sourceFilter, setSourceFilter] = useState<FilterTypes>({
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
    });
    const [logsFilter, setLogsFilter] = useState<LogFilterTypes>({
        log_type: [],
        log_level: [],
        from_date: [],
        to_date: [],
        search: "",
        ordering: ""
    })
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [destDetailsModal, setDestDetailsModal] = useState(false);
    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [reloadDestinations, setReloadDestinations] = useState(false);
    const [reloadLogs, setReloadLogs] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState<any>({ label: "UTC", value: "+0000" });
    const [logs, setLogs] = useState<ConnectionLog[]>([]);
    const [availableDestinations, setAvailableDestinations] = useState<Destination[]>([]);
    const [totalDestinationCount, setTotalDestinationCount] = useState<number>(0);
    const [destinationOffset, setDestinationOffset] = useState<number>(0);
    const [logsOffset, setLogsOffset] = useState<number>(0);
    const [totalLogs, setTotalLogs] = useState<number>(0);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [organizationTotal, setOrganizationTotal] = useState<number>(0);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [orgOverviewOffset, setOrgOverviewOffset] = useState<number>(0);
    const [loadMoreOrgs, setLoadMoreOrgs] = useState(true);
    const [filteredSources, setFilteredSources] = useState<Source[]>([]);
    const [sources, setSources] = useState<Source[]>([]);
    const [totalSources, setTotalSources] = useState<number>(0);
    const [sourcesOffset, setSourcesOffset] = useState<number>(0);
    const [reloadSources, setReloadSources] = useState(false);
    const [destinationUrls, setDestinationUrls] = useState<any[]>([]);
    const [destinationUrlTotal, setDestinationUrlTotal] = useState<number>(0);
    const [destinationUrlOffset, setDestinationUrlOffset] = useState<number>(0);
    const [loadMoreDestinationUrls, setLoadMoreDestinationUrls] = useState(true);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);
    const [destinationTypesTotal, setDestinationTypesTotal] = useState<number>(0);
    const [changesMade, setChangesMade] = useState(false);
    const [addDestModal, setAddDestModal] = useState(false);
    const [errors, setErrors] = useState({})
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const [testActionLoading, setTestActionLoading] = useState(false);
    const [testActionData, setTestActionData] = useState(null);
    const [testActionStatus, setTestActionStatus] = useState<number>(0);
    const navigate = useNavigate();

    const loadDestinationURlsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [dest_urls, total_urls, error] = await loadDestinationUrls(token, offset, search_str, destinationUrls);
            if (!error) {
                setDestinationUrls(dest_urls);
                setDestinationUrlTotal(total_urls);
            }
        }
    }

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            if (!error) {
                setOrganizations(orgs);
                setOrganizationTotal(total_orgs);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            if (!error) {
                setDestinationTypes(dest_types);
                setDestinationTypesTotal(total_dest_types);
            }
        }
    }

    useEffect(() => {
        if (!pageLoaded) {
            loadApiKey();
            loadSources();
            loadDestinationURlsData(destinationUrlOffset, "");
            loadConnectionDetails();
            loadDestinationTypesData(destinationTypesOffset, "");
        }


        if (reloadDestinations) loadDestinations(destinations);
        if (reloadLogs) loadConnectionLogs(destinations);
        if (reloadSources) loadConnectionSources(connectionProvider.id);
        loadOrganizationsData(organizationOffset, "");
    }, [token, reloadDestinations, reloadLogs, reloadSources]);

    useEffect(() => {
        if (subpage === 'provider') {
            loadConnectionDetails();
        }
    }, [subpage])

    // LOAD FUNCTIONS
    const loadConnectionDetails = async () => {
        if (token && typeof token === 'string' && connection_id) {
            try {
                const tempConnection = await getConnectionDetails(token, connection_id);
                setDestinations(tempConnection.destinations);
                setConnection(tempConnection);
                setDestinationFilter(filterDestinationOwner({
                    target: {
                        "name": tempConnection.owner.name,
                        "value": tempConnection.owner.id,
                    }
                }, destinationFilter));

                await Promise.all([
                    loadConnectionSources(tempConnection.id),
                    loadConnectionProvider(),
                    subpage === "logs" && loadConnectionLogs(tempConnection.destinations),
                    loadDestinations(tempConnection.destinations)
                ]);
                setPageLoaded(true);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const loadApiKey = async () => {
        if (token && typeof token === 'string' && connection_id) {
            const tempKey = await getConnectionApiKey(token, connection_id);
            setApiKey(tempKey.api_key);
        }
    }

    // load all destinations, set availableDestinations as all destinations minus the ones already associated with this connection
    const loadDestinations = async (destArray: Destination[]) => {
        if (token && typeof token === 'string') {
            try {
                const destinationFilterQuery = {
                    base_url__in: destinationFilter.base_url__in.map(a => a.id).toString(),
                    enabled: destinationFilter.enabled.map(a => a.id).toString(),
                    type__in: destinationFilter.type__in.map(a => a.id).toString(),
                    owner__in: destinationFilter.owner__in.map(a => a.id).toString(),
                    ordering: destinationFilter.ordering,
                    search: destinationFilter.search,
                    offset: destinationOffset,
                    previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset - page_size),
                    next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (destinationOffset + page_size)
                }
                const temp = await getDestinationsList(token, destinationFilterQuery);

                // check to see if destination in temp.result is in the selected array
                const tempDestArray: Destination[] = temp.results.filter((b: Destination) => !destArray.map((a: Destination) => a.id).includes(b.id))

                setAvailableDestinations(tempDestArray);
                setTotalDestinationCount(temp.count - destArray.length);
                setReloadDestinations(false);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const loadConnectionLogs = async (destination_ids: Destination[]) => {
        let id_array: string[] = destination_ids.map(a => a.id);
        if (token && typeof token === 'string' && connection_id) {
            try {
                id_array.push(connection_id);
                const log_query = {
                    integration__in: id_array.toString(),
                    offset: logsOffset,
                    ordering: logsFilter.ordering,
                    previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (logsOffset - page_size),
                    next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/?pager=limit&limit=" + page_size + "&offset=" + (logsOffset + page_size),
                    log_level: logsFilter.log_level.map(a => a.id).toString(),
                    log_type: logsFilter.log_type.map(a => a.id).toString(),
                    from_date: logsFilter.from_date.length > 0 ? `${logsFilter.from_date[0].id.replace('Z', '')}${selectedTimezone.value}` : "",
                    to_date: logsFilter.to_date.length > 0 ? `${logsFilter.to_date[0].id.replace('Z', '')}${selectedTimezone.value}` : "",
                    search: logsFilter.search
                }
                const temp = await getConnectionLog(token, log_query);
                setLogs(temp.results);
                setTotalLogs(temp.count);
                setReloadLogs(false);
            } catch (err) {
                console.log(err)
            }

        }
    }

    const loadConnectionSources = async (provider_id: string) => {
        if (token && typeof token === 'string') {
            try {
                const sourceQuery = {
                    offset: sourcesOffset,
                    previous: process.env.REACT_APP_BASE_URL_LOCAL + "/sources/?pager=limit&limit=" + page_size + "&offset=" + (sourcesOffset - page_size),
                    next: process.env.REACT_APP_BASE_URL_LOCAL + "/sources/?pager=limit&limit=" + page_size + "&offset=" + (sourcesOffset + page_size)
                }
                const temp = await getSourcesForConnection(token, provider_id, sourceQuery);
                setSources(temp.results);
                setTotalSources(temp.count);
                setFilteredSources(temp.results);
                setReloadSources(false);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const loadConnectionProvider = async () => {
        if (token && typeof token === 'string' && connection_id) {
            const tempIntegration = await getIntegrationDetails(token, connection_id);
            setConnectionProvider(tempIntegration)
        }
    }

    const loadSources = () => {
        // global search
        let tempArray: Source[] = [];

        sources.forEach((source: Source) => {
            if (destinationFilter.search.length > 0 &&
                (source.external_id.toLowerCase().includes(sourceFilter.search.toLowerCase()))) {
                tempArray.push(source);
            }
        })

        if (sourceFilter.base_url__in
            .concat(sourceFilter.type__in, sourceFilter.owner__in, sourceFilter.enabled).length === 0 && sourceFilter.search.length === 0) {
            setFilteredSources(sources)
        } else {
            setFilteredSources(tempArray);
        }
    }

    // SELECT SUB PAGE ON CONNECTION DETAILS PAGE
    function handleSelectSubPage(e: string) {
        if (e === "logs") setReloadLogs(true);
        navigate("/connections/" + connection_id + "/" + e)
    }

    // HANDLE SAVE CHANGE METHODS
    function handleChangeConnectionName(e: any) {
        setConnectionProvider((prevProvider: Provider) => ({
            ...prevProvider,
            name: e.target.value,
        }))
        setChangesMade(true);
    }

    function handleChangeConnectionOwner(e: any) {
        setConnection((prevConnection: any) => ({
            ...prevConnection,
            owner: {
                id: e.value,
                name: e.label,
                description: ""
            },
        }))
        setChangesMade(true);
    }

    function handleSearchOrgDropdown(e: any) {
        if (e.length > 1) loadOrganizationsData(orgOverviewOffset, e);
    }

    function handleLoadMoreOrgs() {
        setOrgOverviewOffset(orgOverviewOffset + page_size)
        loadOrganizationsData(orgOverviewOffset + page_size, "");
        if (organizations.length < organizationTotal) setLoadMoreOrgs(false);
    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        if (organizationOffset + page_size >= organizationTotal) {
            setLoadMoreOrganizations(false);
            return;
        }
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    function handleChangeProviderEndpoint(e: any) {
        setConnectionProvider((prevProvider: Provider) => ({
            ...prevProvider,
            base_url: e.target.value,
        }))
        setChangesMade(true);
    }

    function handleToggleProviderEnabled(e: any) {
        setConnectionProvider((prevProvider: Provider) => ({
            ...prevProvider,
            enabled: !connectionProvider.enabled,
        }))
        setChangesMade(true);
    }

    function handleChangeProviderConfigurations(e: any, action: string) {
        const config_id = connectionProvider.configurations.find((a: ConnectionConfiguration) => a.action.id === action)
        let newConfig: any;
        if (config_id) {
            newConfig = {
                "id": config_id.id,
                "action": action,
                "data": e
            }
        } else {
            newConfig = {
                "action": action,
                "data": e
            }
        }

        if (e) {
            const index_to_find = updatedConfigurations.findIndex((a: any) => a.action === action)
            if (index_to_find === -1) {

                // if this is a new config id, push it to the array
                setUpdatedConfigurations((prevArray: any[]) => [...prevArray, newConfig])
            } else {

                // if it is an existing config id, replace the old config with the new one
                const tempArray = updatedConfigurations
                tempArray[index_to_find] = newConfig
                setUpdatedConfigurations(tempArray)
            }
        }

        setChangesMade(true);
    }

    const handleTestAction = async (action_id: string, action_type: string) => {
        if (token && typeof token === 'string') {
            setTestActionLoading(true);
            setTestActionStatus(0);
            // configuration form data for the relevant action
            let form_data = connectionProvider.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === action_id) !== -1 ? connectionProvider.configurations.find((a: ConnectionConfiguration) => a.action.id === action_id).data : {}
            // find the index in updatedConfigurations that matches the action_id
            let updated_config_index = updatedConfigurations.findIndex((a: any) => a.action === action_id)
            let config_overrides = {}
            // save the data from the updatedConfigurations array if it exists
            if (updated_config_index !== -1) {
                form_data = updatedConfigurations[updated_config_index].data
                config_overrides = { [action_type]: form_data }
            } else {

            }
            let result = await testConnectionAction(token, connection.provider.id, action_type, updated_config_index !== -1 ? config_overrides : form_data);

            if (result) {
                let { data: resp_data, status } = result;
                setTestActionData(resp_data);
                setTestActionStatus(status);
            }
            setTestActionLoading(false);
        }
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        // validate the basic form
        if (connectionProvider.name.length === 0) form_errors.provider_name = "Name is required."
        if (connection.owner.name === "") form_errors.provider_owner = "Select an organization."

        // validate the json form
        setErrors(form_errors);
        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = connectionProvider.type.actions.findIndex((a: any) => a.id === config.action);
            const ajv = new Ajv({ allErrors: true, verbose: true });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);

            const schema = connectionProvider.type.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null; // or any default value you prefer
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }

            const valid = validate(data);
            if (!valid) {
                validate.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1);
                    if (!rsjf_errors[connectionProvider.type.actions[schema_index].id]) {
                        rsjf_errors[connectionProvider.type.actions[schema_index].id] = {};
                    }
                    rsjf_errors[connectionProvider.type.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });

        setRsjfErrors(rsjf_errors);

        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }

    async function handleUpdateConnection() {
        const updatedConnection = {
            "name": connectionProvider.name,
            "owner": connection.owner.id,
            "type": connectionProvider.type.id,
            "base_url": connectionProvider.base_url,
            "enabled": connectionProvider.enabled,
            "configurations": updatedConfigurations
        }
        const updated_destinations = {
            "destinations": destinations.map(a => a.id)
        }
        if (!formIsValid()) {
            setChangesMade(false);
            return;
        }
        if (token && typeof token === 'string') {
            try {
                await updateConnection(token, connectionProvider.id, updatedConnection);
                await addConnectionDestinations(token, connection.default_route.id, updated_destinations);
                toast.success('Connection updated', {
                    position: toast.POSITION.TOP_RIGHT
                });
                navigate("/connections/" + connection_id + "/" + subpage);
                setChangesMade(false);
            } catch (error) {
                console.log(error)
            }

        }
    }

    function handleCancelConnectionChanges() {
        if (changesMade) {
            setShowConfirmationModal(true);
        } else {
            navigate("/");
            setChangesMade(false);
        }
    }

    const handleConfirmNavigation = () => {
        setShowConfirmationModal(false);
        setChangesMade(false);
        navigate("/");
    };

    const handleCancelNavigation = () => {
        setShowConfirmationModal(false);
    };

    // DESTINATION FILTERING FUNCTIONS
    const handleChangeDestinationUrlSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationURlsData(destinationUrlOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationUrls = () => {
        if (destinationUrlOffset + page_size >= destinationUrlTotal) {
            setLoadMoreDestinationUrls(false);
            return;
        }
        setDestinationUrlOffset(destinationUrlOffset + page_size);
        loadDestinationURlsData(destinationUrlOffset + page_size, "");
    }

    function handleChangeGlobalDestinationSearch(searchSubStr: string) {
        setDestinationFilter(changeDestinationGlobalSearch(searchSubStr, destinationFilter));
        setReloadDestinations(true);
    }

    function handleFilterDestinationOwner(e: any) {
        setDestinationFilter(filterDestinationOwner(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleFilterDestinationUrl(e: any) {
        filterDestinationUrl(e, destinationFilter);
        setReloadDestinations(true);
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        if (destinationTypesOffset + page_size >= destinationTypesTotal) {
            setLoadMoreDestinationTypes(false);
            return;
        }
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    function handleFilterDestinationType(e: any) {
        setDestinationFilter(filterDestinationType(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleRemoveDestinationFilterTag(e: any) {
        setDestinationFilter(removeDestinationFilterTag(e, destinationFilter));
        setReloadDestinations(true);
    }

    function handleResetDestinationFilters() {
        setDestinationFilter(resetDestinationFilters());
        setReloadDestinations(true);
    }

    // ADD AND REMOVE DESTINATIONS
    const handleAddDestination = (dest: Destination) => {
        // adjust count for pagination
        setTotalDestinationCount(totalDestinationCount - 1);

        // remove dest from available dests
        let availableArray: Destination[] = [];
        availableDestinations.forEach((availableDest: Destination) => {
            if (availableDest.id !== dest.id) availableArray.push(availableDest)
            setAvailableDestinations(availableArray);
        });

        // add dest to new connection
        let tempArray: Destination[] = destinations;
        tempArray.push(dest);
        setDestinations(tempArray);
        setChangesMade(true);
    }

    const handleRemoveDestination = (dest: Destination) => {
        // adjust count for pagination
        setTotalDestinationCount(totalDestinationCount + 1);

        // add dest to available dests
        let availableArray: Destination[] = availableDestinations;
        availableArray.push(dest);
        setAvailableDestinations(availableArray);

        // remove dest from new connection
        let tempArray: Destination[] = [];
        destinations.forEach((currentDest: Destination) => {
            if (dest.id !== currentDest.id) tempArray.push(currentDest)
            setDestinations(tempArray);
        })
        setChangesMade(true);
    }

    const handleDestinationPageClick = (e: any) => {
        setDestinationOffset(e.selected * page_size);
        // loadDestinations(destinations);  
        setReloadDestinations(true);
    }

    const handleToggleDestinationDetailsModal = async (dest_id: string) => {
        if (dest_id && token && typeof token === 'string') {
            // Append dest_id to the current URL
            navigate(`/connections/${connection_id}/${subpage}/destination/${dest_id}/details`, { replace: false });
        }
        setDestDetailsModal(!destDetailsModal);
    }

    const handleToggleAddDestinationModal = async (openDest_id?: string) => {
        setAddDestModal(!addDestModal)
        if (openDest_id && token && typeof token === 'string') {
            const temp_destination = await getDestinationDetails(token, openDest_id)
            handleAddDestination(temp_destination);
            handleToggleDestinationDetailsModal(openDest_id)
        }
    }

    // SOURCE FILTER FUNCTIONS
    function handleChangeGlobalSourceSearch(searchSubStr: string) {
        setSourceFilter(changeSourceGlobalSearch(searchSubStr, sourceFilter));
        loadSources();
    }

    function handleFilterSourceUrl(e: any) {
        setSourceFilter(filterSourceUrl(e, sourceFilter));
        loadSources();
    }

    function handleRemoveSearchFilterTag(e: any) {
        setSourceFilter(removeSourceFilterTag(e, sourceFilter))
        loadSources();
    }

    function handleResetSourceFilters() {
        setSourceFilter(resetSourceFilters());
        loadSources();
    }

    const requestSortSources = (direction: boolean, sort_by: any) => {
        console.log(direction, " ", sort_by);
    }

    const handleSourcePageClick = (e: any) => {
        setSourcesOffset(e.selected * page_size);
        setReloadSources(true);
    }

    // LOGS FILTERING AND SORTING FUNCTIONS
    const handleLogPageClick = (e: any) => {
        setLogsOffset(e.selected * page_size);
        setReloadLogs(true);
    }

    const handleSortLogTimestamp = (direction: boolean) => {
        setLogsFilter((prevFilter: LogFilterTypes) => ({
            ...prevFilter,
            ordering: direction ? "created_at" : "-created_at"
        }));
        setReloadLogs(true);
    }

    const handleGlobalLogSearch = (searchSubStr: string) => {
        setLogsFilter(logGlobalSearch(searchSubStr, logsFilter));
        setReloadLogs(true);
    }

    const handleSelectTimezone = (e: any) => {
        setSelectedTimezone(e);
    }

    const handleFilterLogType = (e: any) => {
        setLogsFilter(filterLogType(e, logsFilter));
        setReloadLogs(true);

    }
    const handleFilterLogStatus = (e: any) => {
        setLogsFilter(filterLogStatus(e, logsFilter));
        setReloadLogs(true);
    }

    const handleFilterLogsByDate = (e: Date, side: string) => {
        setLogsFilter(filterLogDate(e, side, logsFilter));
        setReloadLogs(true);
    }

    const handleRemoveLogFilterTag = (e: any) => {
        setLogsFilter(removeLogFilterTag(e, logsFilter));
        setReloadLogs(true);
    }

    function handleResetLogFilters() {
        setLogsFilter(resetLogFilters());
        setReloadLogs(true);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {addDestModal &&
                    <ManageDestinationModal
                        onToggleModal={handleToggleAddDestinationModal}
                    />
                }
                {showConfirmationModal &&
                    <CancelChangesConfirmationModal
                        onToggleModal={handleCancelNavigation}
                        onConfirm={handleConfirmNavigation}
                    />
                }
                <div className="flex flex-col">
                    <ConnectionDetails
                        connection={connection}
                        subPage={subpage}
                        onSelectSubPage={handleSelectSubPage}
                        onUpdateConnection={handleUpdateConnection}
                        onCancelConnectionChanges={handleCancelConnectionChanges}
                        changesMade={changesMade}
                    />
                    {(subpage === "overview") && <ConnectionDetailsOverview
                        connection={connection}
                        errors={errors}
                        apiKey={apiKey}
                        connectionProvider={connectionProvider}
                        organizations={organizations}
                        loadMoreOrgs={handleLoadMoreOrgs}
                        orgInputChange={handleSearchOrgDropdown}
                        onChangeConnectionName={handleChangeConnectionName}
                        onChangeConnectionOwner={handleChangeConnectionOwner}
                    />}
                    {(subpage === "provider") && <ConnectionDetailsProvider
                        connection={connection}
                        rsjfErrors={rsjfErrors}
                        connectionProvider={connectionProvider}
                        selectedProviderType={connectionProvider.type}
                        onChangeProviderEndpoint={handleChangeProviderEndpoint}
                        onChangeProviderConfigurations={handleChangeProviderConfigurations}
                        onChangeProviderEnabled={handleToggleProviderEnabled}
                        onTestAction={handleTestAction}
                        testActionLoading={testActionLoading}
                        testActionResponse={testActionData}
                        testActionStatus={testActionStatus}
                    />}
                    {(subpage === "destinations") && <ConnectionDetailsDestination
                        pageLoaded={pageLoaded}
                        destinations={destinations}
                        availableDestinations={availableDestinations}
                        totalDestinationCount={totalDestinationCount}
                        onSelectDestination={handleAddDestination}
                        onRemoveDestination={handleRemoveDestination}
                        onViewDetails={handleToggleDestinationDetailsModal}
                        onDestinationPageClicked={handleDestinationPageClick}
                        destinationOffset={destinationOffset}
                        organizations={organizations}
                        destinationUrls={destinationUrls}
                        destinationTypes={destinationTypes}
                        onAddDestination={handleToggleAddDestinationModal}
                        onLoadMoreOrganizations={handleLoadMoreOrganizations}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                        onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                        onChangeDestinationOwnerFilter={handleFilterDestinationOwner}
                        onChangeDestinationUrlFilter={handleFilterDestinationUrl}
                        onChangeDestinationTypeFilter={handleFilterDestinationType}
                        onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                        onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onChangeGlobalDestinationSearch={handleChangeGlobalDestinationSearch}
                        loadMoreDestinationUrls={loadMoreDestinationUrls}
                        onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                        filters={destinationFilter.base_url__in
                            .concat(destinationFilter.type__in, destinationFilter.owner__in, destinationFilter.enabled)}
                        onRemoveDestinationFilterTag={handleRemoveDestinationFilterTag}
                        onResetDestinationFilters={handleResetDestinationFilters}
                    />}
                    {(subpage === "sources") && <ConnectionDetailsSource
                        sources={sources}
                        totalSources={totalSources}
                        filters={sourceFilter.base_url__in
                            .concat(sourceFilter.type__in, sourceFilter.owner__in, sourceFilter.enabled)}
                        take={page_size}
                        offset={sourcesOffset}
                        onPageClicked={handleSourcePageClick}
                        pageLoaded={pageLoaded}
                        loadingSources={reloadSources}
                        destinationUrls={destinationUrls}
                        onChangeGlobalSourceSearch={handleChangeGlobalSourceSearch}
                        onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                        onChangeSearchUrlFilter={handleFilterSourceUrl}
                        onRemoveSourceFilterTag={handleRemoveSearchFilterTag}
                        onResetSourceFilters={handleResetSourceFilters}
                        requestSortSources={requestSortSources}
                        loadMoreDestinationUrls={loadMoreDestinationUrls}
                        onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                    />}
                    {(subpage === "logs") && <ConnectionDetailsLog
                        logs={logs}
                        filters={logsFilter.log_level
                            .concat(logsFilter.log_type, logsFilter.from_date, logsFilter.to_date)}
                        offset={logsOffset}
                        totalCount={totalLogs}
                        reloadLogs={reloadLogs}
                        selectedTimezone={selectedTimezone}
                        onSelectTimezone={handleSelectTimezone}
                        onPageClicked={handleLogPageClick}
                        requestSort={handleSortLogTimestamp}
                        pageLoaded={pageLoaded}
                        onChangeLogGlobalSearch={handleGlobalLogSearch}
                        onChangeLogStatus={handleFilterLogStatus}
                        onChangeLogType={handleFilterLogType}
                        onChangeLogDate={handleFilterLogsByDate}
                        onRemoveLogFilterTag={handleRemoveLogFilterTag}
                        onResetLogFilters={handleResetLogFilters}
                    />}
                </div>
            </div>
        )
}