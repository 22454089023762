import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionConfiguration, Destination, Organization } from "../common/ObjectTypes";
import SchemaForm from "../common/SchemaForm";
import SelectInput from "../common/SelectInput";
import { AttentionIcon } from "../common/ProjectIcons";

type DestinationDetailsModalConfigProps = {
    destination: Destination,
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    onChange: Function,
    onChangeSelectedDestinationConfig: Function,
    onChangeDestinationEnabled: Function,
    onChangeSelectedDestinationOwner: Function,
    onSave: MouseEventHandler,
    onToggleModal: MouseEventHandler,
    errors: any,
    rsjfErrors: any
}

const DestinationDetailsModalConfig = ({
    destination,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    onChange,
    onChangeSelectedDestinationConfig,
    onChangeDestinationEnabled,
    onChangeSelectedDestinationOwner,
    onSave,
    onToggleModal,
    errors,
    rsjfErrors
}: DestinationDetailsModalConfigProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="flex flex-col mb-2">
                <label className="text-sm text-secondary-gray">{t("Name")} *</label>
                <input
                    id="name"
                    placeholder=""
                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                    value={destination.name ? destination.name : ""}
                    onChange={(e) => onChange(e)}>
                </input>
                {errors.name && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.name}</p></div>}
            </div>
            <div className="flex flex-col my-2">
                {/* owner */}
                <label className="text-sm text-secondary-gray">{t("Organization")} *</label>
                <SelectInput
                    options={organizations.map((org: Organization) => {
                        return {
                            label: org.name,
                            value: org.id
                        }
                    })}
                    className={"basic-single text-black pb-1"}
                    defaultValue={{
                        label: destination.owner.name,
                        value: destination.owner.id
                    }}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    name={"Organization"}
                    onChange={(e: any) => onChangeSelectedDestinationOwner(e)}
                    onMenuScrollToBottom={loadMoreOrgs}
                    handleInputChange={orgInputChange}
                />
                {errors.owner && <div className="text-orange-600 bg-orange-100 py-1 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.owner}</p></div>}
            </div>
            {/* url */}
            <div className="flex flex-col pb-14">
                <label className="text-sm text-secondary-gray">{t("URL")} *</label>
                <input
                    id="base_url"
                    placeholder=""
                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                    value={destination.base_url ? destination.base_url : ""}
                    onChange={(e) => onChange(e)}>
                </input>
                {errors.base_url && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.base_url}</p></div>}
            </div>
            <div className="">
                {/* <label>Configurations</label> */}
                {destination.type.actions.map((item: any) => {
                    return (
                        <div key={item.id} className={(Object.keys(item.schema).length !== 0) ? "pb-14" : "hidden"}>
                            {(Object.keys(item.schema).length !== 0) && <SchemaForm
                                formTitle={item.name ? item.name : ""}
                                schema={item.schema}
                                formdata={destination.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === item.id) !== -1 ? destination.configurations.find((a: ConnectionConfiguration) => a.action.id === item.id).data : {}}
                                onChange={(e: any) => onChangeSelectedDestinationConfig(e, item.id)}
                                isEditable={true}
                                errors={rsjfErrors[item.id]}
                            />}
                        </div>
                    )
                })}
            </div>
            {/* checkbox */}
            <div className="flex flex-row items-start mb-6">
                <input
                    className="mt-1.5 accent-dark-green cursor-pointer"
                    type="checkbox"
                    value=""
                    id="checkboxDefault"
                    checked={destination.enabled}
                    onChange={(e) => onChangeDestinationEnabled(e)} />
                <div className="flex flex-col">
                    <label
                        className="inline-block mx-2"
                    >
                        Activate Destination
                    </label>
                    <label
                        className="inline-block mx-2 text-sm text-secondary-gray"
                    >
                        This connection will send data to its destination.
                    </label>
                </div>
            </div>
            <div>
                {/* submit and cancel button */}
                <div className="flex flex-row-reverse mt-2">
                    <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2"
                        type="submit"
                        onClick={onSave}>
                        Save
                    </button>
                    <button className="bg-very-light-gray text-medium-gray rounded px-7 mx-2"
                        type="button"
                        onClick={onToggleModal}>
                        {t("Cancel")}
                    </button>
                </div>
            </div>
        </div>

    )
}

export default DestinationDetailsModalConfig;