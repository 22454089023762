import React, { useState, useEffect } from "react";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import { RJSFSchema, UiSchema, WidgetProps } from '@rjsf/utils';
import { AttentionIcon, HideString, ShowString } from "../common/ProjectIcons";
import { CustomArrayField } from "./SchemaFormTemplates/CustomArrayField";
import { Tooltip } from "./Tooltip";
import CustomObjectTemplate from "./SchemaFormTemplates/CustomObjectTemplate";
import CustomCheckbox from "./SchemaFormTemplates/CustomCheckbox";

// Define the types for SchemaFormProps
type SchemaFormProps = {
  formTitle: string,
  schema: RJSFSchema,
  formdata?: Object,
  onChange: Function
  isEditable: boolean,
  errors?: any
}

const SchemaForm = ({
  formTitle,
  schema,
  formdata,
  onChange,
  isEditable,
  errors
}: SchemaFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [customSchema, setCustomSchema] = useState<UiSchema>({});
  const [formData, setFormData] = useState(formdata ? formdata : null);
  const printError = (error: any) => console.error("schema form error", { error });
  const togglePassword = () => {
    setShowPassword(!showPassword);

  };

  useEffect(() => {
    const generatedUISchema = generateUISchema(schema);
    setCustomSchema(generatedUISchema);

  }, [schema, errors, showPassword]);

  const generateUISchema = (schema: any) => {
    if (schema && schema.properties) {
      const uiSchema: UiSchema = {};

      if (schema.title) {
        uiSchema['ui:title'] = '';
      }

      Object.keys(schema.properties).forEach((propertyName) => {
        const isRequired = schema.required && schema.required.includes(propertyName);
        const title = schema.properties[propertyName].title || (propertyName.charAt(0).toUpperCase() + propertyName.slice(1)) || '';
        const description = schema.properties[propertyName].description || '';
        uiSchema[propertyName] = {
          'ui:title': (
            <div className="flex items-baseline">
              <span className="mr-1">{title}</span>
              {isRequired && <span className="mr-1"> * </span>}
              {description && <Tooltip description={description} />}
            </div>
          ),
        };

        // Handle arrays, objects, passwords, etc., as in your original code
        if (schema.properties[propertyName].type === 'object') {
          uiSchema[propertyName] = {
            'ui:field': CustomObjectTemplate
          }
        } else if (schema.properties[propertyName].type === 'array') {
          uiSchema[propertyName] = {
            'ui:field': CustomArrayField
          };
        } else if (schema.properties[propertyName].format === 'password') {
          uiSchema[propertyName] = {
            ...uiSchema[propertyName],
            'ui:widget': 'password',
            'ui:options': {
              inputType: showPassword ? 'text' : 'password',
            },
            'ui:help': (
              <div className="flex flex-row">
                <div className="mr-3"> Password Visibility </div> <div onClick={togglePassword} className="flex flex-row cursor-pointer">{showPassword ? <ShowString /> : <HideString />}</div>
              </div>
            ),
          }
        } else if (schema.properties[propertyName].type === 'boolean') {
          uiSchema[propertyName] = {
            'ui:widget': CustomCheckbox,
            'ui:label': ''
          }
        }

        if (errors && errors[propertyName]) {
          const errorMessage = errors[propertyName];
          uiSchema[propertyName] = {
            ...uiSchema[propertyName],
            'ui:options': {
              ...uiSchema[propertyName]['ui:options'],
              className: 'border border-orange-600 focus:border-orange-600 focus:ring-orange-600'
            },
            'ui:help': (
              <div className="text-orange-600 py-1 rounded flex flex-row items-center">
                <AttentionIcon /> <p className="ml-1 mt-0.5">{errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}</p>
              </div>
            ),
          };
        }
      });

      return uiSchema;
    }

    return {};
  };

  const handleFormChange = (formData: any) => {
    setFormData(formData); // Update local state with the updated form data
    onChange(formData); // Propagate the change to the parent component
  };

  return (
    <div className="flex flex-col" id="json-form">
      <div className="text-lg font-semibold text-secondary-gray">{schema.title ? schema.title : formTitle}</div>
      <div className="custom-form">
        <Form
          schema={schema}
          uiSchema={customSchema}
          validator={validator}
          onChange={(e) => {
            setFormData(e.formData)
            onChange(e.formData)
          }}
          onError={printError}
          children={true}
          formData={formData}
          disabled={!isEditable}
        />
      </div>
    </div>
  )
}

export default SchemaForm;
