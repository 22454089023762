import { useKeycloak } from "@react-keycloak/web";
import { t } from "i18next";
import React, { ReactElement } from "react";

type PrivatRouteProps = {
    children: ReactElement
  }

const PrivateRoute = ({ children }: PrivatRouteProps) => {
 const { keycloak } = useKeycloak();

 const isLoggedIn = localStorage.getItem("userAuthorized") !== null;

 return !isLoggedIn ? 
  <div className="text-center text-2xl flex justify-center">
    <div className="bg-neutral-gray-50 w-1/2 mt-6 p-6">
      {t("Login Message")}
    </div>
  </div> : 
    children
    ;
};

export default PrivateRoute;