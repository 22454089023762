import { Member } from "../../components/common/ObjectTypes";
import { handleError } from "../apiUtils";
import { createAxiosInstance } from "../apiUtils";

export async function getUserDetails(token:string, org_id: string, member_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/organizations/" + org_id + "/members/" + member_id);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getEula(token:string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/eula/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function acceptEula(token:string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/eula/accept/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function saveUserDetails(token: string, org_id: string, member_id: string, member: Member){
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.put("/v2/organizations/" + org_id + "/members/" + member_id + "/", member);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getMyDetails(token: string){
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/users/me/");
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}