import React from "react";
import no_results from "../../images/no_results.png"

type NoResultsProps = {
    headerMsg: string,
    labelMsg: string
}

const NoResults = ({
    headerMsg,
    labelMsg
}: NoResultsProps) => {
    return (
        <div className="mx-auto">
            <img src={no_results} alt="empty results icon" className="mx-auto cursor-auto"/>
            <h2 className="text-center text-xl">{headerMsg}</h2>
            <h3 className="text-center text-secondary-gray">{labelMsg}</h3>
        </div>
    )
}

export default NoResults;