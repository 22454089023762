import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { useTranslation } from 'react-i18next';
import { SortingArrows } from "../common/ProjectIcons";
import { Destination, DestinationType, Organization } from "../common/ObjectTypes";
import DestinationFilter from "../destinations/DestinationFilter";
import Pagination from "../common/Pagination";
import NoResults from "../common/NoResults";
import { page_size } from "../../api/apiUtils";

type ConnectionDetailsDestinationProps = {
    pageLoaded: boolean,
    destinations: Destination[],
    availableDestinations: Destination[],
    onSelectDestination: Function,
    onRemoveDestination: Function,
    onDestinationPageClicked: Function,
    onViewDetails: Function,
    destinationOffset: number,
    totalDestinationCount: number,
    organizations: Organization[],
    destinationUrls: string[],
    destinationTypes: DestinationType[],
    onAddDestination: Function,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    onLoadMoreOrganizations: Function,
    loadMoreOrganizations: boolean,
    onChangeOwnerSearchBar: ChangeEventHandler,
    onChangeDestinationOwnerFilter: ChangeEventHandler,
    onChangeDestinationUrlFilter: ChangeEventHandler,
    onChangeDestinationTypeFilter: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadMoreDestinationTypes: boolean,
    onChangeGlobalDestinationSearch: Function,
    filters: any,
    onRemoveDestinationFilterTag: Function,
    onResetDestinationFilters: Function
}

const ConnectionDetailsDestination = ({
    pageLoaded,
    destinations,
    availableDestinations,
    totalDestinationCount,
    onSelectDestination,
    onRemoveDestination,
    onViewDetails,
    onDestinationPageClicked,
    destinationOffset,
    organizations,
    destinationUrls,
    destinationTypes,
    onAddDestination,
    onChangeDestinationUrlSearchBar,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    onLoadMoreOrganizations,
    loadMoreOrganizations,
    onChangeOwnerSearchBar,
    onChangeDestinationOwnerFilter,
    onChangeDestinationUrlFilter,
    onChangeDestinationTypeFilter,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadMoreDestinationTypes,
    onChangeGlobalDestinationSearch,
    filters,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters
}: ConnectionDetailsDestinationProps) => {
    const {t} = useTranslation();
    return (
        <div className="mt-8">
            {/* div with text and add button */}
            <div className="flex flex-row justify-between items-center w-full">
                <label className="text-secondary-gray">Data from the provider are sent to the following destinations.</label>
            </div>
            {/* div with search bar and table*/}
            <div className="flex flex-row mt-8 mb-12 mr-2 overflow-x-hidden">
                <DestinationFilter 
                    destinationUrls={destinationUrls}
                    destinationTypes={destinationTypes}
                    organizations={organizations}
                    filters={filters}
                    onChangeGlobalSearch={onChangeGlobalDestinationSearch}
                    onRemoveTag={onRemoveDestinationFilterTag}
                    onResetFilters={onResetDestinationFilters}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    onChangeEndpoint={onChangeDestinationUrlFilter}
                    onChangeType={onChangeDestinationTypeFilter}
                    onChangeOwner={onChangeDestinationOwnerFilter}
                    showStatus={false}
                    showUrlFilter={true}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                    onLoadMoreOrganizations={onLoadMoreOrganizations}
                    onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                />
                <div className="w-5/6">
                    {/* selected destinations */}    
                    <div className="flex flex-col w-full">
                        <div className="mb-3">
                            <label className="text-off-black">Selected destinations</label>
                        </div>
                        {destinations.length < 1 && 
                            <NoResults 
                                headerMsg="No Destinations Selected"
                                labelMsg="Select from the list of available destinations below."
                            />}
                        {destinations.length >= 1 &&
                            <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                    <tr>
                                        <th className="p-3 ml-3">
                                        
                                        </th>
                                        <th className="p-3">
                                            Health
                                        </th>
                                        <th className="p-3">
                                            Name
                                        </th>
                                        <th className="p-3">
                                            {t("Destination")}
                                            <span><SortingArrows /></span>
                                        </th>
                                        <th className="p-3">
                                            Organization
                                        </th>
                                        <th className="p-3">
                                            Type
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm">
                                    {destinations.map((destination: Destination) => {
                                        let dest_url: string | URL;
                                        try {
                                            dest_url = new URL(destination.base_url).hostname
                                        } catch (err) {
                                            dest_url = ""
                                        }
                                        return(
                                            <tr key={destination.id}
                                            className="border border-gray-lines bg-white"
                                            >
                                                <td className="p-3">
                                                    <input
                                                        className="accent-dark-green cursor-pointer"
                                                        type="checkbox"
                                                        value={destination.id}
                                                        id={destination.id}
                                                        name={destination.name}
                                                        checked={true}
                                                        onChange={() => onRemoveDestination(destination)}
                                                    />
                                                </td>
                                                <td className="p-3">
                                                    {/* {destination.status === "healthy" ? "" : <AttentionIcon /> } */}
                                                </td>
                                                <td className="p-3">
                                                    {destination.name}
                                                </td>
                                                <td className="p-3 text-blue-basic cursor-pointer" onClick={() => onViewDetails(destination.id)}>
                                                    {dest_url}
                                                </td>
                                                <td className="p-3">
                                                    {destination.owner.name}
                                                </td>
                                                {/* <td className="p-3">
                                                    -------
                                                </td> */}
                                                {/* <td className="p-3">
                                                    -------
                                                </td> */}
                                                <td className="p-3">
                                                    {destination.type.name}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                        <div className="mt-6">
                            {/* available destinations */}
                            <div className="flex flex-row justify-between mb-3 items-end">
                                    <label className="text-off-black mt-8">Available destinations</label>
                                    <div className="flex flex-col">
                                        <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2 mb-2"
                                            onClick={() => onAddDestination(null)}>
                                            Add New Destination
                                        </button>
                                        <Pagination 
                                            showText={false}
                                            handlePageClick={onDestinationPageClicked}
                                            pageCount={Math.ceil(totalDestinationCount / page_size)}
                                            resultStart={destinationOffset + 1}
                                            resultEnd={(destinationOffset + page_size) < totalDestinationCount ? (destinationOffset + page_size) : totalDestinationCount}
                                            totalCount={totalDestinationCount}
                                            take={page_size}
                                            loading={!pageLoaded}
                                            mainclassname="flex flex-row items-end mr-0"
                                        />
                                    </div>
                            </div>
                            {availableDestinations.length < 1 && 
                                <NoResults 
                                    headerMsg="No Results Found"
                                    labelMsg="You may need to adjust your search or filters to find what you are looking for."
                                />}
                            {availableDestinations.length >= 1 &&
                                <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                    <tbody>
                                        {availableDestinations.map((destination: Destination) => {
                                            return(
                                                <tr key={destination.id}
                                                className="border border-gray-lines bg-white text-sm"
                                                >
                                                    <td className="p-3">
                                                    <input
                                                        className="accent-dark-green ml-3 cursor-pointer"
                                                        type="checkbox"
                                                        value={destination.id}
                                                        id={destination.id}
                                                        name={destination.name}
                                                        checked={false}
                                                        onChange={() => onSelectDestination(destination)}
                                                    />
                                                    </td>
                                                    <td className="p-3">
                                                        {/* {destination.status === "healthy" ? "" : <AttentionIcon /> } */}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.name}
                                                    </td>
                                                    <td className="p-3 text-blue-basic cursor-pointer" onClick={() => onViewDetails(destination.id)}>
                                                        {destination.base_url}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.owner.name}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.type.name}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ConnectionDetailsDestination;