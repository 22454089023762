import React from 'react'
import Select from 'react-select'

type SelectInputProps = {
    options: Array<any>,
    className: string,
    defaultValue: object,
    isDisabled: boolean,
    isLoading: boolean,
    isClearable: boolean,
    isSearchable: boolean,
    isMulti: boolean,
    name: string,
    onChange: Function,
    onMenuScrollToBottom?: any,
    handleInputChange?: any
}

const SelectInput = ({
    options,
    className,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isMulti,
    name,
    onChange,
    onMenuScrollToBottom,
    handleInputChange
}: SelectInputProps) => {
    return (
        <Select 
            options={options}
            className={className}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            name={name}
            onChange={(e) => onChange(e)}
            onMenuScrollToBottom={onMenuScrollToBottom}
            onInputChange={handleInputChange}
        />
    )    
};

export default SelectInput;