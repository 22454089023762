import React, { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { Destination, DestinationType, Organization, ProviderType, RoutingRule, Source } from "../common/ObjectTypes";
import { NextArrow, PrevArrow, SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import SourceFilter from "./SourcesFilter";
import NoResults from "../common/NoResults";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";

type SourcesListProps = {
    sources: Source[],
    totalCount: number,
    take: number,
    offset: number,
    onPageClicked: MouseEventHandler,
    loading: boolean,
    organizations: Organization[],
    destinationTypes: DestinationType[],
    destinationUrls: string[],
    providerTypes: ProviderType[],
    filters: any[],
    globalSearchStr?: string,
    onChangeGlobalSearch: Function,
    onChangeOwner: ChangeEventHandler,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeDestinationType: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadMoreDestinationTypes: boolean,
    onChangeEndpoint: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    onChangeProviderTypeSearchBar: ChangeEventHandler,
    onChangeProviderType: ChangeEventHandler,
    loadMoreProviderTypes: boolean,
    onLoadMoreProviderTypes: Function,
    onRemoveTag: any,
    onResetFilters: any,
    requestSort: Function,
    onViewDetails: Function,
}

const SourcesList = ({
    sources,
    totalCount,
    take,
    offset,
    onPageClicked,
    loading,
    organizations,
    destinationTypes,
    destinationUrls,
    providerTypes,
    filters,
    globalSearchStr,
    onViewDetails,
    onChangeGlobalSearch,
    onChangeOwner,
    onChangeOwnerSearchBar,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationType,
    onLoadMoreDestinationTypes,
    onChangeDestinationTypeSearchBar,
    loadMoreDestinationTypes,
    onChangeEndpoint,
    onChangeDestinationUrlSearchBar,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    onChangeProviderTypeSearchBar,
    onChangeProviderType,
    loadMoreProviderTypes,
    onLoadMoreProviderTypes,
    onResetFilters,
    onRemoveTag,
    requestSort,
}: SourcesListProps) => {
    const [sortDirection, setSortDirection] = useState(true);
    const [sortByColumn, setSortByColumn] = useState("external_id");
    const [sortByDateCreated, setSortByDateCreated] = useState(false);

    return (
        <div className="bg-very-light-gray">
            <div className="mb-3 flex justify-between">
                <div>
                    <h1 data-testid="sources_page_header" className="text-3xl font-bold mb-3">
                        Sources
                    </h1>
                </div>
            </div>
            <div className="flex flex-row items-start w-full">
                {/* FILTERS */}
                <SourceFilter
                    filters={filters}
                    globalSearchStr={globalSearchStr}
                    organizations={organizations}
                    destinationTypes={destinationTypes}
                    destinationUrls={destinationUrls}
                    providerTypes={providerTypes}
                    onChangeOwner={onChangeOwner}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                    onLoadMoreOrganizations={onLoadMoreOrganizations}
                    loadMoreProviderTypes={loadMoreProviderTypes}
                    onLoadMoreProviderTypes={onLoadMoreProviderTypes}
                    onChangeDestinationType={onChangeDestinationType}
                    onChangeProviderType={onChangeProviderType}
                    onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                    onChangeProviderTypeSearchBar={onChangeProviderTypeSearchBar}
                    onChangeEndpoint={onChangeEndpoint}
                    onChangeGlobalSearch={onChangeGlobalSearch}
                    onRemoveTag={onRemoveTag}
                    onResetFilters={onResetFilters}
                    showOrgFilter={true}
                    showProviderTypeFilter={true}
                    showDestinationTypeFilter={true}
                    onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                />
                {sources.length < 1 &&
                    <NoResults
                        headerMsg="No Results Found"
                        labelMsg="You may need to adjust your search or filters to find what you are looking for."
                    />}
                {sources.length >= 1 &&
                    <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                        <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                            <tr>
                                <th className="p-3">
                                    External ID
                                    <span onClick={() => {
                                        requestSort("external_id", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByColumn("external_id");
                                        setSortByDateCreated(false);
                                    }}>
                                        {sortByColumn === "external_id" ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                                <th className="p-3">
                                    Provider
                                    <span onClick={() => {
                                        requestSort("integration__id", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByColumn("integration__id");
                                        setSortByDateCreated(false);
                                    }}>
                                        {sortByColumn === "integration__id" ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                                <th className="p-3">
                                    Destinations
                                </th>
                                <th className="p-3">
                                    Routes
                                </th>
                                <th className="p-3">
                                    Update Frequency
                                </th>
                                <th className="p-3">
                                    Last Update
                                </th>
                                <th className="p-3">
                                    Created At
                                    <span onClick={() => {
                                        requestSort("created_at", sortDirection)
                                        setSortDirection(!sortDirection)
                                        setSortByDateCreated(true);
                                    }}>
                                        {sortByDateCreated ?
                                            sortDirection ? <SortedUp /> : <SortedDown />
                                            : <SortingArrows />}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        {loading ?
                            <tbody>
                                <tr>
                                    <td colSpan={7} className="p-3 text-center">
                                        <Spinner />
                                    </td>
                                </tr>
                            </tbody>
                            : <tbody className="text-sm">
                                {sources.map((source: Source) => {
                                    const newdate = new Date(source.created_at)
                                    return (
                                        <tr key={source.id}
                                            className="border border-gray-lines bg-white">
                                            <td className="p-3">
                                                {source.external_id}
                                            </td>
                                            <td className="p-3">
                                                {source.provider.name}
                                            </td>
                                            <td className="p-3 text-blue-basic cursor-pointer">
                                                {source.destinations.map((dest: Destination) => {
                                                    let dest_url: string | URL;
                                                    try {
                                                        dest_url = new URL(dest.base_url).hostname
                                                    } catch (err) {
                                                        dest_url = ""
                                                    }
                                                    return (<p key={dest.id} onClick={() => onViewDetails(dest.id)}>{dest_url}</p>)
                                                })}
                                            </td>
                                            <td className="p-3">
                                                {source.routing_rules.map((rule: RoutingRule) => {
                                                    return (<p key={rule.id}>{rule.name}</p>)
                                                })}
                                            </td>
                                            <td className="p-3">
                                                {source.update_frequency}
                                            </td>
                                            <td className="p-3">
                                                {source.last_update}
                                            </td>
                                            <td className="p-3">
                                                {newdate.toString()}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>}
                <Pagination
                    showText={true}
                    handlePageClick={onPageClicked}
                    pageCount={Math.ceil(totalCount / take)}
                    resultStart={offset + 1}
                    resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                    initialPage={Math.floor(offset / take)}
                    totalCount={totalCount}
                    take={take}
                    loading={loading}
                    mainclassname="fixed bottom-0 pl-72 pr-24 w-full flex flex-row justify-between bg-very-light-gray opacity-80"
                />
            </div>
        </div>
    )
};

export default SourcesList;