import { getSources } from './sourcesApi';
import { Source } from '../../components/common/ObjectTypes';

const loadSources = async (
    keycloak_token: string,
    sourceSearchInput: any,
    sourcesquery: any
): Promise<[Source[], number, Error | null]> => {
    let total_sources = 0;
    try {
        const query = {
            provider_type__in: sourcesquery.provider_type__in.map((a: any) => a.id).toString(),
            destination_url__in: sourcesquery.destination_url__in.map((a: any) => a.id).toString(),
            owner__in: sourcesquery.owner__in.map((a: any) => a.id).toString(),
            destination_type__in: sourcesquery.destination_type__in.map((a: any) => a.id).toString(),
            search: sourcesquery.search,
            ordering: sourcesquery.ordering,
            offset: sourcesquery.offset,
        }
        const temp = await getSources(keycloak_token, query);
        // const new_array: Source[] = sources.concat(temp.results);
        const duplicates_removed: Source[] = Array.from(new Set(temp.results.map((obj: Source) => obj.id))).map(id => {
            return temp.results.find((obj: Source) => obj.id === id)!;
        });
        const alphabetized = duplicates_removed.sort((a, b) => a.id.localeCompare(b.id));
        if (sourceSearchInput.length === 0) total_sources = temp.count;
        return [alphabetized, total_sources, null]
    } catch (err: any) {
        console.log(err)
        return [[], 0, err];
    }
}

export default loadSources;