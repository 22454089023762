import React, { ChangeEventHandler } from "react";
import { Destination, DestinationType, Organization } from "../common/ObjectTypes";
import Pagination from "../common/Pagination";
import DestinationFilter from "../destinations/DestinationFilter";
import NoResults from "../common/NoResults";
import { page_size } from "../../api/apiUtils";

type StepThreeProps = {
    destinations: Destination[],
    destinationTypes: DestinationType[],
    organizations: Organization[],
    selectedDestinations: Destination[],
    onSelectDestination: Function,
    onRemoveDestination: Function,
    filters: any[],
    totalDestinationCount: number,
    destinationOffset: number,
    onDestinationPageClicked: Function,
    onChangeStateFilter: ChangeEventHandler,
    onChangeTypeFilter: ChangeEventHandler,
    onChangeOwnerFilter: ChangeEventHandler,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadMoreDestinationTypes: boolean,
    onGlobalDestinationFilter: Function,
    onRemoveDestinationFilterTag: any,
    onResetDestinationFilters: any,
    pageLoaded: boolean,
    onAddDestination: Function
}

const NewConnectionStepThree = ({
    destinations,
    destinationTypes,
    organizations,
    selectedDestinations,
    onSelectDestination,
    onRemoveDestination,
    filters,
    totalDestinationCount,
    destinationOffset,
    onChangeStateFilter,
    onChangeTypeFilter,
    onChangeOwnerFilter,
    onChangeOwnerSearchBar,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationUrlSearchBar,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadMoreDestinationTypes,
    onDestinationPageClicked,
    onGlobalDestinationFilter,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters,
    pageLoaded,
    onAddDestination
}: StepThreeProps) => {

    return (
        <div className="mt-8">
            {/* text, list of destinations, filter panel on the left */}
            <div className="flex flex-col w-full">
                <label className="text-off-black text-lg">Select the destination where you'd like to see this data.</label>
                {/* filter panel and tables of destinations */}
                <div className="flex flex-row mt-8">
                    {/* filter panel */}
                    <DestinationFilter 
                        destinationUrls={[]}
                        destinationTypes={destinationTypes}
                        organizations={organizations}
                        filters={filters}
                        onChangeGlobalSearch={onGlobalDestinationFilter}
                        onRemoveTag={onRemoveDestinationFilterTag}
                        onResetFilters={onResetDestinationFilters}
                        onChangeState={onChangeStateFilter}
                        onChangeType={onChangeTypeFilter}
                        onChangeOwner={onChangeOwnerFilter}
                        onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onLoadMoreOrganizations={onLoadMoreOrganizations}
                        onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                        onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                        showStatus={true}
                        showUrlFilter={false}
                        loadMoreDestinationUrls={false}
                        onLoadMoreDestinationUrls={() => {}}
                    />
                    <div className="flex flex-col w-full">
                        {/* selected destinations */}
                        <div className="mb-3">
                            <label className="text-off-black">Selected destinations</label>
                        </div>
                        {selectedDestinations.length < 1 && 
                            <NoResults 
                                headerMsg="No Destinations Selected"
                                labelMsg="Add a destination from the list of available destinations"
                            />}
                        
                        {selectedDestinations.length >= 1 &&
                            <table className="table-auto h-auto w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                    <tr>
                                            <th className="p-3 ml-3">
                                            
                                            </th>
                                            <th className="p-3">
                                                Name
                                            </th>
                                            <th className="p-3">
                                                Organization
                                            </th>
                                            <th className="p-3">
                                                Type
                                            </th>
                                            <th className="p-3">
                                                State
                                            </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDestinations.map((destination: Destination) => {
                                        return(
                                            <tr key={destination.id} className="border border-gray-lines hover:bg-light-blue bg-white">
                                                <td className="p-3">
                                                <input
                                                    className="accent-dark-green ml-3 cursor-pointer"
                                                    type="checkbox"
                                                    value={destination.id}
                                                    id={destination.id}
                                                    name={destination.name}
                                                    checked={true}
                                                    onChange={() => onRemoveDestination(destination)}/>
                                                </td>
                                                <td className="p-3">
                                                    {destination.name}
                                                </td>
                                                <td className="p-3">
                                                    {destination.owner.name}
                                                </td>
                                                <td className="p-3">
                                                    {destination.type.name}
                                                </td>
                                                <td className="p-3">
                                                    {destination.enabled ? "Active" : "Inactive"}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                
                            </table>}
                        <div className="mt-6">
                            {/* available destinations */}
                            <div className="flex flex-row justify-between mb-3 items-end">
                                <label className="text-off-black mt-8">Available destinations</label>
                                <div className="flex flex-col">
                                    <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2 mb-2"
                                        onClick={() => onAddDestination(null)}>
                                        Add New Destination
                                    </button>
                                    <Pagination 
                                        showText={false}
                                        handlePageClick={onDestinationPageClicked}
                                        pageCount={Math.ceil(totalDestinationCount / page_size)}
                                        resultStart={destinationOffset + 1}
                                        resultEnd={(destinationOffset + page_size) < totalDestinationCount ? (destinationOffset + page_size) : totalDestinationCount}
                                        totalCount={totalDestinationCount}
                                        take={page_size}
                                        loading={!pageLoaded}
                                        mainclassname="flex flex-row items-end mr-0"
                                    />
                                </div>
                            </div>
                            {destinations.length < 1 && 
                                <NoResults 
                                    headerMsg="No Results Found"
                                    labelMsg="You may need to adjust your search or filters to find what you are looking for."
                                />}
                            {destinations.length >= 1 &&
                                <table className="table-auto h-auto w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                    <tbody>
                                        {destinations.map((destination: Destination) => {
                                            return(
                                                <tr key={destination.id} className="border border-gray-lines hover:bg-light-blue bg-white">
                                                    <td className="p-3">
                                                    <input
                                                        className="accent-dark-green ml-3 cursor-pointer"
                                                        type="checkbox"
                                                        value={destination.id}
                                                        id={destination.id}
                                                        name={destination.name}
                                                        checked={false}
                                                        onChange={() => onSelectDestination(destination)}/>
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.name}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.owner.name}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.type.name}
                                                    </td>
                                                    <td className="p-3">
                                                        {destination.enabled ? "Active" : "Inactive"}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewConnectionStepThree;