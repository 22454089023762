import React, { useState, useEffect } from "react";
import { Clear, Clear_Large, SearchIcon } from "./ProjectIcons";

type GlobalSearchProps = {
    onChangeGlobalSearch: Function
    savedString?: string
}

const GlobalSearch = ({
    onChangeGlobalSearch,
    savedString
}: GlobalSearchProps) => {
    const [subStr, setSubStr] = useState(savedString ? savedString : "");
    // useeffect to rerender the component when savedString changes
    useEffect(() => {
        setSubStr(savedString ? savedString : "");
    }, [savedString]);

    return (
        <div className="flex flex-col max-w-xs items-start" data-testid='globalsearch'>
            <div className="flex flex-row">
                <div className="relative">
                    <input
                        id="globalsearch"
                        data-testid='globalsearch-input'
                        placeholder=""
                        value={subStr}
                        className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2 pr-8" // Adjust padding to make space for the "X"
                        onChange={(e) => setSubStr(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onChangeGlobalSearch(subStr);
                            }
                        }}
                    />
                    {subStr && (
                        <button
                            className="absolute right-0 top-0 mt-3.5 mr-1 text-dark-green"
                            onClick={() => {
                                setSubStr('')
                                onChangeGlobalSearch('')
                            }}
                        >
                            <Clear_Large />
                        </button>
                    )}
                </div>
                <button className="m-2 bg-dark-green hover:bg-status-green text-white rounded text-sm p-2 h-auto"
                    type="button"
                    data-testid='globalsearch-button'
                    onClick={() => onChangeGlobalSearch(subStr)}
                >
                    <SearchIcon />
                </button>
            </div>
        </div>
    )
};

export default GlobalSearch;