import React from "react";
import RemoveMemberModal from "./RemoveMemberModal";
import { Member } from "../common/ObjectTypes";

type ManageRemoveMemberModalProps = {
    onToggleModal: Function,
    onRemoveMember: Function,
    memberid: string,
    currentMember: Member,
    superuser: boolean
}

export function ManageRemoveMemberModal({
    onToggleModal,
    onRemoveMember,
    memberid,
    currentMember,
    superuser
}: ManageRemoveMemberModalProps){

    const toggleModalVisibility: React.MouseEventHandler<HTMLButtonElement> = () => {
        onToggleModal();
    }
    
    return (
        <RemoveMemberModal 
            onToggleModal={toggleModalVisibility}
            onRemoveMember={onRemoveMember}
            memberid={memberid}
            currentMember={currentMember}
            superuser={superuser}
        />
    )
}