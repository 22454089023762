import { getQueryString, handleError, page_size } from "../apiUtils";
import { createAxiosInstance } from "../apiUtils";

export async function getDestinationsList(token: string, query: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/integrations/?action_type=push&pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getDestinationTypes(token: string, query?: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = query ? getQueryString(query): {}
    try {
        const resp = await axiosInstance.get("/v2/integrations/types/?action_type=push&pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getDestinationUrls(token: string, query?: object) {
    const axiosInstance = await createAxiosInstance(token);
    const query_string = getQueryString(query)
    try {
        const resp = await axiosInstance.get("/v2/integrations/urls/?action_type=push&pager=limit&limit=" + page_size + "&" + query_string);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function addDestination(token: string, new_destination: object) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.post("/v2/integrations/", new_destination);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function getDestinationDetails(token: string, destination_id: string) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.get("/v2/integrations/"+ destination_id);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}

export async function updateDestination(token: string, destination_id: string, destination: object) {
    const axiosInstance = await createAxiosInstance(token);
    try {
        const resp = await axiosInstance.patch("/v2/integrations/"+ destination_id + "/", destination);
        return resp.data;
    } catch (err) {
        handleError(err);
    }
}
