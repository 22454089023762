import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';

export function useKeycloakToken() {
  const { keycloak } = useKeycloak();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    let timeoutId: any;

    timeoutId = setTimeout(() => {
      if (keycloak.sessionId === undefined || !keycloak.authenticated) {
        setShowLoginModal(true);
      } else {
        setShowLoginModal(false);
      }
    }, 1000);

    // Set up the onTokenExpired event handler
    keycloak.onTokenExpired = () => {
      console.log('Token expired');
      setShowLoginModal(true); // Show the login modal instead of logging in directly
    };

    // Clean up the event handler and timeout on unmount
    return () => {
      keycloak.onTokenExpired = () => {}; // Assign an empty function instead of null
      clearTimeout(timeoutId); // Clear the timeout
    };
  }, [keycloak.sessionId]); // Only run this effect when keycloak changes

  return [keycloak.token, showLoginModal];
}

export function LoginModal({ showLoginModal }: any) {
  const { keycloak } = useKeycloak();

  const handleLogin = () => {
    keycloak.login();
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto bg-medium-gray bg-opacity-80`}>
      <div className="opacity-100 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="z-30 opacity-100 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="text-center bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-2xl leading-6 font-medium text-black">
              Session Expired
            </h3>
            <div className="mt-2">
              <p className="text-secondary-gray">
                Your session has expired. Please log in again.
              </p>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" className="font-bold w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base text-white hover:bg-status-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleLogin}>
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}