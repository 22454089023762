import React, { useState } from 'react';
import { ToolTipIcon } from './ProjectIcons';

export const Tooltip: React.FC<{ description: string }> = ({ description }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative">
            <div
                className="text-blue-500 cursor-pointer mb-2"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <ToolTipIcon />
            </div>
            {showTooltip && (
                <div className="w-40 absolute top-full left-0 bg-white border border-gray-200 p-2 rounded shadow-lg z-10">
                    {description}
                </div>
            )}
        </div>
    );
};