import React from 'react';
import ReactPaginate from 'react-paginate';
import { BackArrow, NextArrow, PrevArrow } from './ProjectIcons';

type PaginationProps = {
    showText: boolean,
    handlePageClick: Function,
    pageCount: number,
    resultStart: number,
    resultEnd: number,
    totalCount: number,
    take: number,
    loading: boolean,
    mainclassname: string,
    initialPage?: number;
}

const Pagination = ({
    showText,
    handlePageClick,
    pageCount,
    resultStart,
    resultEnd,
    totalCount,
    take,
    loading,
    mainclassname,
    initialPage = 0,
}: PaginationProps) => {
    return (
        <div className={mainclassname}>
            {showText ?
                <div>
                    {totalCount > 0 && (
                        loading ? (
                            <span className='w-full text-secondary-gray py-2'>
                                Loading...
                            </span>
                        ) : (
                            <span className='w-full text-secondary-gray py-2'>
                                Showing {resultStart} to {resultEnd} of {totalCount}
                            </span>
                        )
                    )}
                </div> :
                <div>
                    {/* {totalCount > 0 && <span className='w-full text-secondary-gray py-2'>Load more</span>} */}
                </div>
            }
            <ReactPaginate
                previousLabel={totalCount > take ? <PrevArrow /> : null}
                nextLabel={totalCount > take ? <NextArrow /> : null}
                breakLabel={". . ."}
                pageCount={pageCount}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination flex flex-row text-secondary-gray justify-end my-0 pb-1 mx-8 "}
                pageClassName={"mx-4"}
                activeClassName={"active border border-secondary-gray rounded-full px-2"}
                forcePage={initialPage}
            />
        </div>
    )
}

export default Pagination;