import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/common/Nav";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import PrivateRoute from "./components/common/PrivateRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrganizationPage from "./components/organizations/OrganizationPage";
import SourcesPage from "./components/sources/SourcesPage";
import ConnectionsPage from "./components/connections/ConnectionsPage";
import AccountPage from "./components/account/AccountPage";
import { ManageOrganizationDetails } from "./components/organizations/ManageOrganizationDetails";
import DestinationsPage from "./components/destinations/DestinationsPage"
import { ManageConnectionDetails } from "./components/connections/ManageConnectionDetails";
import { ManageAddConnection } from "./components/connections/ManageAddConnection";
import { useKeycloakToken, LoginModal } from './components/common/useKeycloak';
import { ManageDestinationDetails } from "./components/destinations/ManageDestinationDetails";

function App() {
  return (
    <div className="h-screen bg-very-light-gray">
      <ReactKeycloakProvider authClient={keycloak}>

        <BrowserRouter>
          <Nav />
          <div className="px-5 xl:px-12 py-6">
            <Routes>
              <Route
                path="/organizations"
                element={
                  <PrivateRoute>
                    <OrganizationPage />
                  </PrivateRoute>
                } />
              <Route
                path="/organizations/:org_id"
                element={
                  <PrivateRoute>
                    <ManageOrganizationDetails />
                  </PrivateRoute>
                } />
              <Route
                path="/sources"
                element={
                  <PrivateRoute>
                    <SourcesPage />
                  </PrivateRoute>
                } />
              <Route
                path="/sources/destination/:dest_id/:dest_subpage"
                element={
                  <PrivateRoute>
                    <div>
                      <ManageDestinationDetails />
                      <SourcesPage />
                    </div>
                  </PrivateRoute>
                } />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <ConnectionsPage />
                  </PrivateRoute>
                } />
              <Route
                path="/destination/:dest_id/:dest_subpage"
                element={
                  <PrivateRoute>
                    <div>
                      <ManageDestinationDetails />
                      <ConnectionsPage />
                    </div>
                  </PrivateRoute>
                } />
              <Route
                path="/connections/add"
                element={
                  <PrivateRoute>
                    <ManageAddConnection />
                  </PrivateRoute>
                } />
              <Route
                path="/connections/add/:dest_id/:dest_subpage"
                element={
                  <PrivateRoute>
                    <div>
                      <ManageDestinationDetails />
                      <ManageAddConnection />
                    </div>
                  </PrivateRoute>
                } />
              <Route
                path="/connections/:connection_id/:subpage"
                element={
                  <PrivateRoute>
                    <ManageConnectionDetails />
                  </PrivateRoute>
                } />
              <Route
                path="/connections/:connection_id/:subpage/destination/:dest_id/:dest_subpage"
                element={
                  <PrivateRoute>
                    <div>
                      <ManageDestinationDetails />
                      <ManageConnectionDetails />
                    </div>
                  </PrivateRoute>
                } />
              <Route
                path="/destinations"
                element={
                  <PrivateRoute>
                    <DestinationsPage />
                  </PrivateRoute>
                } />
              <Route
                path="/destinations/destination/:dest_id/:dest_subpage"
                element={
                  <PrivateRoute>
                    <div>
                      <ManageDestinationDetails />
                      <DestinationsPage />
                    </div>
                  </PrivateRoute>
                } />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                } />
            </Routes>
            <ToastContainer />
            <Content />
          </div>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

function Content() {
  const [token, showLoginModal] = useKeycloakToken();

  return (
    <>
      {showLoginModal && <LoginModal showLoginModal={showLoginModal} />}
    </>
  );
}

export default App;
