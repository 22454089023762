import React, { FormEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Organization } from "../common/ObjectTypes";

type OrganizationModalProps = {
    organization: Organization,
    onChange: Function,
    onToggleModal: MouseEventHandler,
    onSave: FormEventHandler
    newOrg: boolean
}

const OrganizationModal = ({
    organization,
    onChange,
    onToggleModal,
    onSave,
    newOrg
}: OrganizationModalProps) => {
    const {t} = useTranslation();
    return (
        <div className="absolute left-0">
            <div className="fixed z-20 top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
            onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/2 h-2/3 shadow rounded">
                    <div className="flex flex-row-reverse">
                        <button 
                            type="button" 
                            onClick={onToggleModal} 
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" 
                            data-modal-hide="authentication-modal"
                        >
                            <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">{t("Close modal")}</span>
                        </button>
                    </div>
                    <div className="px-6 pb-12 lg:px-8 h-full">
                        <div className="flex justify-between">
                            <h1 className="text-3xl font-semibold">{newOrg ? "Add" : "Update"} Organization</h1>
                        </div>
                        <div onSubmit={onSave} className="h-full">
                            <div className="flex flex-col my-5 h-full justify-between">
                                <div>
                                    {/* name and description */}
                                    <div className="flex flex-col w-1/2">
                                        <label>{t("Name")}*</label>
                                        <input
                                            id="name"
                                            placeholder=""
                                            className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                                            value={organization.name}
                                            onChange={(e) => onChange(e)}>
                                        </input>
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label>Description</label>
                                        <input
                                            id="description"
                                            placeholder=""
                                            className="my-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                                            value={organization.description}
                                            onChange={(e) => onChange(e)}>
                                        </input>
                                    </div>
                                </div>
                                {/* submit and cancel button */}
                                <div className="flex flex-row-reverse mt-2 mb-9">
                                    <button className="bg-dark-green hover:bg-status-green text-white rounded py-2 px-8 ml-2 shadow"
                                        type="submit"
                                        onClick={onSave}>
                                        {newOrg ? "Create" : "Save"}
                                    </button>
                                    <button className="bg-very-light-gray text-medium-gray rounded py-2 px-3 mx-2"
                                        type="button"
                                        onClick={onToggleModal}>
                                        {t("Cancel")}
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganizationModal;