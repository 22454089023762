import { getDestinationUrls } from "./destinationsApi";
import { page_size } from "../apiUtils";

const loadDestinationUrls = async (
    keycloak_token: string,
    page_offset: number,
    destinationUrlSearchInput: any,
    destinationUrls: any[],
): Promise<[any[], number, Error | null]> => {
    let total_destination_urls = 0;
    try {
        const query = {
            offset: page_offset,
            search: destinationUrlSearchInput,
            search_fields: "base_url",
            ordering: 'name',
            previous: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/urls/?action_type=push&pager=limit&limit=" + page_size + "&offset=" + (page_offset-page_size),
            next: process.env.REACT_APP_BASE_URL_LOCAL + "/integrations/urls/?action_type=push&pager=limit&limit=" + page_size + "&offset=" + (page_offset+page_size)
        }
        const temp = await getDestinationUrls(keycloak_token, query);
        const url_array: any[] = [];
        temp.results.forEach((element: any) => {
            if(element.base_url !== "") url_array.push(element);
        })
        const all_urls = [...url_array.map((a: any) => a.base_url)]
        if (destinationUrlSearchInput.length === 0) total_destination_urls = temp.count;
        return [destinationUrls.concat(all_urls), total_destination_urls, null]
    } catch (err: any) {
        console.log(err);
        return [[], 0, err];
    }
}

export default loadDestinationUrls;