type FilterTags = {
    name: string,
    id: string
};

export const changeSourceGlobalSearch = (searchSubString: string, sourceFilter: any) => {
    sourceFilter.search = searchSubString;
    return sourceFilter;
}

export const filterSourceUrl = (e: any, sourceFilter: any) => {
    let tempArray: FilterTags[] = sourceFilter.base_url__in
    if(tempArray.map(a => a.id).includes(e.target.value)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if(tempArray[item].id !== e.target.value) removed.push(tempArray[item])
        }
        sourceFilter[e.target.id] = removed
    } else {
        let newTag = {
            name: e.target.name,
            id: e.target.value
        }
        tempArray.push(newTag)
        sourceFilter[e.target.id] = tempArray
    }
    return sourceFilter;
}

export const removeSourceFilterTag = (e: any, sourceFilter: any) => {
    if((sourceFilter.base_url__in.map((a: any) => a.id).includes(e))) {
        let tempArray: FilterTags[] = sourceFilter.base_url__in
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if(tempArray[item].id !== e) removed.push(tempArray[item])
        }
        sourceFilter.base_url__in = removed
    }
    return sourceFilter;
}

export const resetSourceFilters = () => {
    return {
        base_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
     }
}