import axios from "axios";

export async function createAxiosInstance(token: string) {
  let bearerToken: string
    if(token) {
        bearerToken = "bearer " + token;
    } else {
        bearerToken = "";
    }
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL_LOCAL
    });
    
    axiosInstance.defaults.headers.common["Authorization"] = bearerToken;
    axiosInstance.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
    axiosInstance.defaults.headers.common["accept"] = "application/json, text/plain, */*";
    
    // resource interceptor
    axiosInstance.interceptors.response.use(
        function(response) {
            if (response.status === 200) localStorage.setItem("userAuthorized", true.toString());
            return response;
        }
    );
    return axiosInstance;
}

export async function handleResponse(response: any) {
    if (response.ok) {
      localStorage.setItem("userAuthorized", true.toString());
      return response.json();
    }
    if (response.status === 400) {
      // So, a server-side validation error occurred.
      // Server side validation returns a string error message, so parse as text instead of json.
      const error = await response.text();
      throw new Error(error);
    }
    throw new Error("Network response was not ok.");
  }

  // In a real app, would likely call an error logging service.
  export function handleError(error: any) {
    // eslint-disable-next-line no-console
    console.error("API call failed. " + error);
    throw error;
  }

export function getQueryString(query: any) {
  let queryString = "";
  let str = [];

  for (var p in query) {
    var type = typeof query[p];
    if (type === "object") {
      var arr = query[p];
      for (var v in arr) {
        if (arr[v] !== null)
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(arr[v]));
      }
    } else {
      if (query[p] !== null)
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(query[p]));
    }
  }
  queryString = str.join("&");
  return queryString;

}

export const page_size = 15;